@import '../../assets/css/colors';

.CardContainer {
    width: 261px;
    border-radius: 5px;
    user-select: none;
    cursor: pointer;
}

.linkContainer {
    text-decoration: none;
    color: $dark
}

.imageContainer {
    height: inherit !important;
}