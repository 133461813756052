.container{
    width: 100%;
    border: 1px solid black;
    padding: 8px;
    border-radius: 8px;
}

.item {
    width: 100%;
    border: 1px solid black;
    border-radius: 8px;
    box-shadow: 0px 6px 0px #000000;
    padding: 4px;
    margin-bottom: 16px;
}

.baseVariant {
    width: 100%;
    border: 1px solid #9EDF34;
    border-radius: 8px;
    box-shadow: 0px 6px 0px #9EDF34;
    padding: 4px;
    margin-bottom: 16px;
}