#progress {
    position: absolute;
    width: calc(100% / 7);
    height: 4px;
    color: rgba(0, 0, 0, 1);
    border: 1px solid black;
    border-radius: 10px;
    top: calc(15%);
    left: calc(50% - 100% / 14);
    opacity: 0;
    transition: opacity 0.3s 0.3s;
}

#progress.show {
    transition-delay: 0s;
    opacity: 1;
}

#progress>.bar {
    width: 100%;
    height: 100%;
    background-color: currentColor;
    border-radius: 10px;
    transform-origin: top left;
    transform: scaleX(0);
}