.tableTitle {
    font-weight: bolder !important;
    font-size: 1em !important;
}

.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 380px;
    width: 100%;
}