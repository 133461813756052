@import '../../assets/css/colors';

.productBox {
    background-color: $background-gray;
    height: 142px;
    padding: 16px 24px 0 24px
}

.descriptionContainer {
    overflow-wrap: anywhere;
}